body {
  overflow-x: hidden;
}

main>.wrapper {
  position: relative;
  /* allows to apply sticky to sidebar */
  overflow-x: unset;
}
.item {

  background-color: #8ce8df86;
}
.form-label.required:not(.form-builder):after {
  content: "*";
  color: red;
  position: absolute;
  top: 0;
  left: -5px;
}

.form-check-label.required:not(.form-builder):after {
  content: "*";
  color: red;
  position: absolute;
  top: 0;
  left: -5px;
}

.modal-body .required.form-check:after {
  content: "*";
  color: red;
}
.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, .15);
  z-index: 1020;
}
#sidebar {
  /* border: 2px solid black; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
}

.sticky {
  /* border: 3px solid black;
  background-color: rgb(233, 191, 191); */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#sidebar ul li:hover {
  background-color: #6d769b;
}
#sidebar ul li span svg {
  margin-right: 5px;
}

#sidebar ul hr {
  margin: 0px;
}

#sidebar h6 {
  padding: 10px;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.radio-button .col-form-label {
  padding: 0;
}
