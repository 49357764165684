.wrapper-class {
  padding: 5px;
  border: 1px solid #ccc;
  color: #4b555b;
}

.editor-class {
  background-color: #dadada;
  padding: 1rem;
  border: 1px solid #dadada;
}

.toolbar-class {
  border: 1px solid #ccc;
}