/* body {
    background-color: gray;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

*/
.container {
    width: 100%;
    height: 150px;
    top: 10%;
    left: 10%;
}

.sigContainer {
    border: 1px solid rgb(128, 128, 128);
    width: 100%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
}

.sigPad {
    width: 100%;
    height: 100%;
}

/* .buttons {
    width: 100%;
    height: 30px;
}

*/
.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

.inputSignatureField {
    font-family: "DancingScript-VariableFont_wght";
    text-align: center;
    /* margin: auto; */
    /* background-color: transparent; */
    overflow: hidden;
    border-radius: 0px;
    border: none;
    font-size: 50px;
    /* margin: 0; */
    /* height: 110px !important; */
    -webkit-box-shadow: none;
    box-shadow: none;
    /* padding: 20px 0 5px; */
    /* text-indent: 30px; */
}

.signatureWrapper {
    position: absolute;
    font-family: "DancingScript-VariableFont_wght";
    text-align: center;
    /* padding: 10px; */
    display: inline-block;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    border-radius: 1px;
    border: 1px solid #cecece;
    border: none;
    font-size: 70px;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: -1;

}

.signatureSpan {
    padding: 10px;
}