body {
    overflow-y: scroll;
}

.fill-form-wrapper {
  position: absolute;
  margin: 0;
  min-height: 100vh;
  min-width: 100%;
  /* background: #1e88e5; */
}

.client-create {
  margin: 5% 10%;
}

.client-create form {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
}

.client-create form .react-grid-layout.layout {
  border-radius: 5px;
  border: none !important;

}

.client-create h1,
.client-create h2,
.client-create h3,
.client-create h4,
.client-create h5,
.client-create h6 {
  color: #67757c;
  font-weight: 400;
}
.client-create .required.form-check:after {
  content: "*";
  color: red;
}
/* Override default bootstrap :valid styles */
.client-create .form-control:valid {
  border-color: #ced4da !important;
  padding-right: initial !important;
  background: initial !important;
}
.client-create .form-control:valid:focus {
  border-color: initial !important;
  box-shadow: initial !important;
}
.client-create .form-control:valid:focus + .input-group-append .input-group-text {
  border-color: initial !important;
}
.client-create .form-control:valid + .input-group-append .input-group-text {
  border-color: initial !important;
}
