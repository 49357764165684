.control-sidebar {
    position: absolute;
    /* margin-top: 56px; */
    padding: 10px 5px;
    z-index: 1010;
    min-width: 300px;
    /* max-width: 300px; */
    height: 100%;
    background: #58628b;
    color: #fff;
    transition: all 0.3s;
    /* transition: all 0.3s; */
}

.control-sidebar,
.control-sidebar-bg {
    top: 0;
    right: -300px;
    width: 300px;
    -webkit-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
}


aside {
    display: block;
}

.control-sidebar-open {
    right: 0;

}