/* NothingYouCouldDo font -- not open source!!! */
/* @font-face {
  font-family: 'NothingYouCouldDo';
  src: url("/assets/fonts/nothing_you_could_do/NothingYouCouldDo.ttf") format('truetype');
} */

/* DancingScript-VariableFont_wght font -- is open source */
@font-face {
  font-family: 'DancingScript-VariableFont_wght';
  src: url("/assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf") format('truetype');
  /* TTF file for CSS3 browsers */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =============== react resizable styles  ===============*/
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
/* =============== End react resizable styles  ===============*/

/* =============== Form Fields ===================*/
label > p{
  margin-bottom: 0;
}
/* =============== END Form Fields ===============*/

/* PDF el */

.pdf_el {
  position: absolute !important;
}

.pdf_page_continer {
  margin-bottom: 10px;
}
/* Theme styles */
.btn-primary {
  background-color: #7460ee;
  border-color: #7460ee;
  color: #fff;
}

.btn-primary:hover {
  background-color: #9080f5;
  border-color: #9080f5;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
  box-shadow: 0 0 0 0.2rem rgba(115, 96, 238, 0.5);
}
/* ====== Mobile view */
/* 
ask user to rotate screen smaller then 576px
and adjust font size for the creens less then 992px
affected page => /fill-form/<uuid> 
*/
.rotate-device {
  display: none;
}

.rotate-device div {
  display: none;
}

@media (max-width: 576px) {
  .rotate-device {
    z-index: 997;
    min-width: 100%;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    top: 0;
    background: url(/assets/rotate-device.png) no-repeat 50% rgba(244, 246, 249, 0.9);
    display: block;

  }

  .rotate-device div {
    color: #67757c;
    position: absolute;
    top: 70%;
    min-width: 100%;
    font-size: 28px;
    text-align: center;
    display: block;
  }

  body {
    overflow: hidden;
  }

  p {
    font-size: 10px;
  }

  .client-create {
    margin: 5% 3% !important;
  }
}

.rotate-device button {
    position: absolute;
    bottom: 10%;
    left: 40%;
    z-index: 999;
}

@media (min-width: 576px) {
  p {
    font-size: 10px;
  }

  .client-create {
    margin: 5% 5% !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  p {
    font-size: 11px;
  }

  .client-create {
    margin: 5% 5% !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  p {
    font-size: 13px;
  }

  .client-create {
    margin: 5% 10% !important;
  }
}

/* ====== END Mobile view */